.documentName:hover{
    color: rgb(8,81,145);
}.documentName:hover {
  color: rgb(8, 81, 145);
}
.heading{
  font-size: 18px;
  color:white
}

.laneMainDiv{
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
  column-gap: 20px;
}

.laneMainBDiv{
  display: flex;
  justify-content:space-evenly;
  margin-top: 15px;
  column-gap: 20px;
}

.arrowStyle {
  width: 0;
  height: 0;
  border-left: 25px solid yellow;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}
.arrowStyle2 {
  width: 0;
  height: 0;
  border-left: 25px solid lightgray;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}
.arrowsign{
width: 40px;
 background-color: yellow;
 height: 20px;
 display: flex;
  align-items: center ;
}
.documentContainer{
 width: 100%;
height: calc(100vh - 17.8rem)
}
.box {
  display: flex;
  -webkit-display: flex;
  justify-content: space-around;
  -webkit-justify-content: space-around;
  height: 70px;
  width: 400px;
  margin: auto;
  align-items: center;
  -webkit-align-items: center;
  transform: translate (-50%, -40%);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
  background-color: white;
  box-sizing: border-box;
  padding: 8px;
}
.imgBox {
  height: 60px;
}
.imgBox img {
  width: 90px;
  height: 100%;
}
.addressBox {
  padding: 5px;
}
.addressBox :first-child {
  font-weight: bold;
}

.addressBox p {
  color: #2976be;
  font-size: 15px;
  font-family: sans-serif;
  text-align: start;
}
.addressBox :last-child {
  line-height: 2px;
}

.invisibleScroll ::-webkit-scrollbar {
  display: none !important;
}

.trnxnDiv {
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.rtdWidthDiv{
  width: 980px;
}

.rtdDelegateWidthDiv{
  width: 950px;
}

.rtdNavbar{
    display: inline;
}

.rtdNavContainer{
    padding: 5;
    font-size: 19px;
    font-weight:bold;
    color: gray;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 150;
}
 /* signal Icon css */
 .signalIconOutline {
  width: 33px;
  height: 30px;
  border-radius: 4px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.signalIndicator {
  width: 19px;
  height: 19px;
  position: relative;
  border-radius: 9px;
  overflow: hidden;
}

.fullIndicator {
  width: 19px;
  height: 19px;
  position: relative;
  border-radius: 9px;
  overflow: hidden;
  background-color: var(--fullColor);
}

.signalIndicator::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
  background-color: var(--topColor);
}

.signalIndicator::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50%;
  border-top: 1.6px solid rgb(42, 42, 42);
  bottom: 0;
  left: 0;
  background-color: var(--bottomColor);
}

/* legends css starts */
.tableRow {
  height: 50px;
  width: 100%;
  /* border: 2px solid green; */
}

.symbolsColumn {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  /* border: 1px solid red; */
}

.infoColumn {
  width: 80%;
  text-align: left;
}

.topHalfCircle {
  width: 20px;
  height: 10px;
  border-radius: 10px 10px 0 0;
  border: 1px solid black;
  overflow: hidden;
  position: relative;
}

.topHalfCircle::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10px;
  border-bottom: 1px solid black;
  border-radius: 10px 10px 0 0;
}

.bottomHalfCircle {
  width: 20px;
  height: 10px;
  border-radius: 0 0 10px 10px;
  border: 1px solid black;
  overflow: hidden;
  position: relative;
}

.bottomHalfCircle::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10px;
  border-top: 2px solid black;
  border-radius: 0 0 10px 10px;
}

.fullCircle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid black;
}

.contractTypeBadge{
  display: inline-block;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  padding: 2px 5px;
  color: #fff;
  margin-right: 5px;
  border-radius: 4px;
  background-color: rgb(6 82 145);
}
               

 /* legend css ends */

@media only screen and (max-width: 1250px) {
  .box {
    width: 300px;
  }
  .imgBox {
    height: 60px;
  }
  .imgBox img {
    width: 70px;
    height: 100%;
  }

  .addressBox :first-child {
    font-weight: bold;
    font-size: 14px;
  }

  .rtdWidthDiv{
    width: 85%;
  }

  .rtdDelegateWidthDiv{
    width: 85%;
  }

  .addressBox p {
    color: #2976be;
    font-size: 15px;
    font-family: sans-serif;
    text-align: start;
    padding-left: 5px;
    margin-top: -5px;
  }
  .addressBox :last-child {
    line-height: 2px;
  }

  .invisibleScroll ::-webkit-scrollbar {
    display: none !important;
  }
}


.box{
    /* width: max-content;*/
    width: 400px;
  }
 .imgBox{
    height: 60px;
  }
 .imgBox img{
    width: 90px;
    height:100%;
  }
 .addressBox{
    /* height: 100%; */
    /* width: 90%; */
    height: 90%;
    width: 100%;
    margin: auto;
    margin-top: -5px;
    /* line-height: 3px; */
    line-height: 19px;
    box-sizing: border-box;
    padding: 5px;
}
  .addressBox :first-child{
    font-weight: bold;
    font-size: 17px;
  }

  .addressBox p {
    color: #2976be;
    font-size: 15px;
    font-family: sans-serif;
    text-align: start;
    padding-left: 5px;
    margin-top: -5px;
  }
  .addressBox :last-child {
    line-height: 2px;
  }

.invisibleScroll ::-webkit-scrollbar {
    display: none !important;
  }

@media only screen and (max-width: 1250px) {
  .box {
    width: 300px;
  }
  .imgBox {
    height: 60px;
  }
  .imgBox img {
    width: 70px;
    height: 100%;
  }

  .addressBox :first-child {
    font-weight: bold;
    font-size: 14px;
  }

  .addressBox p {
    color: #2976be;
    font-size: 15px;
    font-family: sans-serif;
    text-align: start;
    padding-left: 5px;
    margin-top: -5px;
  }
  .addressBox :last-child {
    line-height: 2px;
  }

  .invisibleScroll ::-webkit-scrollbar {
    display: none !important;
  }

  .rtdWidthDiv{
    width: 86%;
  }
}
@media only screen and (max-width: 1024px) {
  .documentContainer{
    width: 100%;
   height: calc(100vh - 22.8rem)
   }

  .laneMainDiv{
    justify-content: start;
    margin-top: 5px;
  }

  .laneMainBDiv{
    justify-content: space-evenly;
    margin: 5px 0 0 5px;
  }

  .rtdWidthDiv{
    width: 92%;
  }
}
@media only screen and (max-width: 850px) {
  .documentContainer{
    width: 100%;
   height: calc(100vh - 21.8rem)
   }

  .rtdWidthDiv{
    width: 91%;
  }
}

@media only screen and (max-width: 760px) {
    .rtdNavbar{
      display: none
     }
  }

@media screen and (max-width: 710px) {
  .rtdWidthDiv{
    width: 680px !important;
  }
}
@media only screen and (max-width: 480px) {
  .heading{
    font-size: 14px;
  }
  .arrowStyle {
    border-left: 12.5px solid yellow;
    border-top: 7.5px solid transparent;
    border-bottom: 7.5px solid transparent;
  }
    .arrowStyle2 {
      border: 15px solid transparent;
      border-left: 15px solid lightgray;
    }

  .rtdWidthDiv{
    width: 680px !important;
  }

  .arrowsign{
    width: 40px;
     background-color: yellow;
     height: 18px;
     display: flex;
      align-items: center ;
    }
    .documentContainer{
      width: 100%;
     height: calc(100vh - 19.8rem)
     }
}
