.formsAndAffiliatesOuterCont {
  border: 1px solid grey;
  border-radius: 10px;
  padding: 5px 5px 0 5px;
  height: 100%;
  position: relative;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) */
}

.formsAndAffiliatesOuterCont .TabPaneTitle {
  text-transform: capitalize;
}

.formsAndAffiliatesOuterCont .legend {
  height: fit-content;
  margin: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.formsAndAffiliatesOuterCont .searchInputBoxCol {
  width: 80%;
}

.formsAndAffiliatesOuterCont .documentCont {
  height: 73vh;
  overflow: hidden;
}

.formsAndAffiliatesOuterCont .documentCont .documentScroll {
  height: 62vh;
  overflow: auto;
}

.formsAndAffiliatesOuterCont .documentCont .documentScroll::-webkit-scrollbar {
  width: 5px;
}

.formsAndAffiliatesOuterCont .documentCont .sortBox {
  display: flex;
  background: #3a74b6;
}

.documentsName:hover {
  color: rgb(8, 81, 145);
  color: #3d82e3;
}

/* FilterForms CSS */

.filterBoxTitle {
  text-align: center;
  color: #178DFA;
  text-transform: uppercase;
  font-weight: bold;
}

.filterToolBox {
  margin: 15px 0;
  display: flex;
  justify-content: space-around;
}

.filterOptionContainer {
  max-height: 50vh;
  overflow: auto;
}

.filterOptionContainer::-webkit-scrollbar {
  width: 5px;
}

.titleCompanyDetailCont {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.titleCompanyDetailCont::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.titleCompanyDetailCont .titleCompanyDetailTable td {
  border: none;
  padding: 3px;
}

.titleCompanyDetailCont .titleCompanyDetailTable .label {
  font-weight: bold;
  color: #2976be;
  vertical-align: baseline;
  width: 40%;
}

.titleCompanyDetailCont .titleCompanyDetailTable .value {
  font-weight: 500;
  vertical-align: baseline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: "block";
}

@media screen and (max-width: 550px) {
  .formsAndAffiliatesOuterCont {
    /* outline: auto; */
  }

  .formsAndAffiliatesOuterCont .legend span {
    font-size: 18px !important;
  }

  .formsAndAffiliatesOuterCont .documentCont .documentScroll {
    /* font-size: small; */
  }

  .titleCompanyDetailCont .titleCompanyDetailTable .label {
    width: 30%;
  }

}
